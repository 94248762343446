import React from "react";
import InputMask from "react-input-mask";
import "./input.scss";

type InputData = {
  label: string;
  name: string;
  type?: string;
  className?: string;
  handler: any;
};

function Input({ label, name, type, className = "", handler }: InputData) {
  return (
    <div className={`form-group ${className}`}>
      <label>{label}</label>
      <input type={type || "text"} name={name} onChange={handler} />
    </div>
  );
}

export default Input;

export const ExpirationDateInput = ({
  label,
  name,
  type,
  className = "",
  handler
}: InputData) => {
  return (
    <div className={`form-group ${className}`}>
      <label>{label}</label>
      <InputMask
        type={type || "text"}
        name={name}
        mask="99/9999"
        maskChar=" "
        onChange={handler}
      />
    </div>
  );
};

export const CardNumberInput = ({
  label,
  name,
  type,
  className = "",
  handler
}: InputData) => {
  return (
    <div className={`form-group ${className}`}>
      <label>{label}</label>
      <InputMask
        type={type || "text"}
        name={name}
        mask="9999 9999 9999 9999"
        maskChar=" "
        onChange={handler}
      />
    </div>
  );
};

export const SecurityCodeInput = ({
  label,
  name,
  type,
  className = "",
  handler
}: InputData) => {
  return (
    <div className={`form-group ${className}`}>
      <label>{label}</label>
      <InputMask
        type={type || "text"}
        name={name}
        mask="999"
        maskChar=" "
        onChange={handler}
      />
    </div>
  );
};
