import React from 'react';
import './button.scss';

type FormButtonData = {
    text: string,
    handler: any
}

function FormButton({ text, handler }: FormButtonData) {
    return (
        <button className="form-button" onClick={handler}>{text}</button>
    )
}

export default FormButton;