import React from 'react'
import searchIcon from '../../assets/images/search.svg'

const Search = () => {
    return (
        <div className="search-wrapper">
            <input type="text" placeholder="Search for an order or a name" />
            <img className="search-icon" src={searchIcon} alt="" />
        </div>
    )
}

export default Search
