import React from 'react'
import { LayoutWrapperMobile } from '../components/Layout/Index'
import '../components/Payment/payment.scss';
import Processing from '../components/Payment/Processing';


function ProcessingPayment() {
    return (
        <>
            <LayoutWrapperMobile>
                <div className="payment-container">
                    <Processing />
                </div>
            </LayoutWrapperMobile>
        </>
    )
}

export default ProcessingPayment