import React, {useState, useEffect} from 'react';
import DetailItem, {GhostDetailItem, GhostEventItem} from "./DetailItem";
import "./details.scss";
import { Input, Button, CurrencyInput } from "../Layout/Index";
import { FormattedMessage, FormattedNumber } from "react-intl";
import ApiService from '../../services/ApiService';
import {FormatDate, FormatTime, State, Country} from '../../helpers/util';

const BoxDetails = (props: any) => {

  const [details, setDetails] = useState<any>();
  const [refund, setRefund] = useState<any>();

  useEffect(() => {
    ApiService.getOrder(props.number)
    .then(response => response.json())
    .then(data => {
      setDetails(data);
      setRefund({
        storeId: 1,
        paymentId: data.payment.id,
        amount: 0,
        percentage: 0,
        status: "none",
        reference: ""
      });
    });
  }, []);

  const handleRefundAmountChange = (value: any) => {
    setRefund((prevState: any) => ({
      amount: value,
      storeId: prevState.storeId,
      paymentId: prevState.paymentId,
      reference: prevState.reference,
      percentage: Number(((value / details.payment.amount.brl) * 100).toFixed(2)),
      status: (prevState.amount != value ? "none" : prevState.status)
    }))
  }

  const handleRefundReferenceChange = (event: any) => {
    event.persist();
    setRefund((prevState: any) => ({
      reference: event.target.value,
      amount: prevState.amount,
      storeId: prevState.storeId,
      paymentId: prevState.paymentId,
      percentage: prevState.percentage,
      status: prevState.status
    }))
  }

  const handleRefundButtonClick = (event: any) => {
    document.getElementsByClassName("send-refund-btn")[0].setAttribute('disabled', 'disabled');

    ApiService.PostRefund({
      storeId: refund.storeId,
      paymentId: refund.paymentId,
      amount: refund.amount,
      reference: refund.reference
    }).then(response => {
      document.getElementsByClassName("send-refund-btn")[0].removeAttribute('disabled');
      if(response.status == 200) {

        setRefund((prevState: any) => ({
          storeId: prevState.storeId,
          paymentId: prevState.paymentId,
          amount: prevState.amount,
          percentage: prevState.percentage,
          reference: prevState.reference,
          status: "success"
        }))
      }
      else {
        setRefund((prevState: any) => ({
          storeId: prevState.storeId,
          paymentId: prevState.paymentId,
          amount: prevState.amount,
          percentage: prevState.percentage,
          reference: prevState.reference,
          status: "rejected"
        }))
      }
      return response.json()
    }).
    then(data => {
      console.log(data);
    })

  }

  const refundFeedbackMessage = () => {
    if(refund) {
      switch(refund.status) {
        case "success":
          return <div className={"value green"}> {"Refund has been sent sucessfully"} </div>;
        case "rejected":
          return <div className={"value red"}> {"Refund has not been sent sucessfully"} </div>;
        default:
          return "";
      }
    }
  }

  const RefundEventItem = ({parcialRefund, percentage, amount, createdOn} : any) => {
    const description = parcialRefund ? "Partially Refunded" : "Fully Refunded"
    return (
    <li className="event-item">
      <ol className="content">
        <li className="description">{description}</li>
        <li>R$ <FormattedNumber minimumFractionDigits={2} maximumFractionDigits={2} value={amount} /> | <FormattedNumber minimumFractionDigits={2} maximumFractionDigits={2} value={percentage} />%</li>
        <li>{FormatTime(new Date(createdOn))}</li>
      </ol>
    </li>
    )
  }

  const boxEvents = () => {
    if(details) {

      return (
      <ol className="event-items">
        {details.payment.refunds.map((value :any, index :number) => RefundEventItem(value))}

        <li className="event-item">
          <ol className="content">
            <li className="description">Received</li>
            <li>R$ <FormattedNumber minimumFractionDigits={2} maximumFractionDigits={2} value={details.payment.amount.brl} /></li>
            <li>{FormatTime(new Date(details.createdOn))}</li>
          </ol>
        </li>

        <li className="event-item last">
          <ol className="content">
            <li className="description">Created</li>
            <li>R$ <FormattedNumber minimumFractionDigits={2} maximumFractionDigits={2} value={details.payment.amount.brl} /></li>
            <li>{FormatTime(new Date(details.createdOn))}</li>
          </ol>
        </li>
      </ol>
    )} else {
      return (
        <ol className="event-items">

          <li className="event-item">
            <ol className="ghost-content">
              <li><GhostEventItem width={150} height={15} /></li>
              <li><GhostEventItem width={150} height={15} /></li>
              <li><GhostEventItem width={150} height={15} /></li>
            </ol>
          </li>

          <li className="event-item">
            <ol className="ghost-content">
              <li><GhostEventItem width={150} height={15} /></li>
              <li><GhostEventItem width={150} height={15} /></li>
              <li><GhostEventItem width={150} height={15} /></li>
            </ol>
          </li>
          
        </ol> 
      )
    }
  }

  const boxOrderDetails = () => {
    if(details)
    {
      let status: any = {
        name: details.status
      };
      if(details.status == "Confirmed")
      {
        status["color"] = "green";
      }
      else {
        status["color"] = "red"
      }
      return (
        <>
        <DetailItem label="Order number" value={details.number.slice(0,7)} color="" />
        <DetailItem label="Order Status" value={status.name} color={status.color} />
        <DetailItem label="Type" value={(details.subscription != null) ? 'Recurring' : 'Single'} color="" />
        <DetailItem
          label="Created on"
          value= {FormatTime(new Date(details.createdOn))}
          color=""
        />
        <DetailItem
          label="Received on"
          value= {FormatTime(new Date(details.createdOn))}
          color=""
        />
        </>
      )
    }
    else {
      return (
        <>
        <GhostDetailItem label="Order number" color="" width={90} height={18} />
        <GhostDetailItem label="Order Status" color="green" width={65} height={18} />
        <GhostDetailItem label="Type" color="" width={110} height={18} />
        <GhostDetailItem label="Created on" color="" width={110} height={18} />
        <GhostDetailItem label="Received on" color="" width={110} height={18} />
        </>
      )
    }
    
  }

  const boxCustomerDetails = () => {
    if(details)
    {
      return (
        <>
        <DetailItem label="First name:" value={details.customer.firstName} />
        <DetailItem label="Surname:" value={details.customer.surname} />
        <DetailItem label="CPF:" value={details.customer.cpf} />
        <DetailItem label="Date of birth:" value={FormatDate(new Date(details.customer.birthdate))} />
        {/* <DetailItem label="Gender:" value="11/2020" /> */}
        <DetailItem label="Email:" value={details.customer.email} />
        <DetailItem label="Phone:" value={details.customer.phone} />
        <DetailItem label="Street:" value={details.customer.address.street} />
        <DetailItem label="House number:" value={details.customer.address.number} />
        <DetailItem label="Reference:" value={details.customer.address.reference} />
        <DetailItem label="District:" value={details.customer.address.district} />
        <DetailItem label="City:" value={details.customer.address.city} />
        <DetailItem label="State:" value={State(details.customer.address.state)} />
        <DetailItem label="Country:" value={Country(details.customer.address.country)} />
        </>
      )
    }
    else {
      return (
        <>
        <GhostDetailItem label="First name:" width={150} height={18} />
        <GhostDetailItem label="Surname:" width={150} height={18}  />
        <GhostDetailItem label="CPF:" width={150} height={18}  />
        <GhostDetailItem label="Date of birth:" width={150} height={18} />
        {/* <DetailItem label="Gender:" value="11/2020" /> */}
        <GhostDetailItem label="Email:" width={150} height={18} />
        <GhostDetailItem label="Phone:" width={150} height={18} />
        <GhostDetailItem label="Street:" width={150} height={18} />
        <GhostDetailItem label="House number:" width={150} height={18} />
        <GhostDetailItem label="Reference:" width={150} height={18} />
        <GhostDetailItem label="District:" width={150} height={18} />
        <GhostDetailItem label="City:" width={150} height={18} />
        <GhostDetailItem label="State:" width={150} height={18} />
        <GhostDetailItem label="Country:" width={150} height={18} />
        </>
      )
    }
  }

  const boxPaymentDetails = () => {

    const Random = () => {
      return Math.floor(Math.random() * (100 - 70 + 1)) + 70;
    }

    if(details)
    {
      let status;
      if(details.payment.status == "Authorized")
      {
        status = {
          name: "Authorized",
          color: "green"
        }
      }
      else {
        status = {
          name: "Not Authorized",
          color: "red"
        }
      }
      return (
        <>
        <DetailItem label="Status:" value={status.name} color={status.color} />
        <DetailItem label="Transaction code:" value={details.payment.number} />
        <DetailItem label="Fraud score:" value={`${Random()}%`} />
        <DetailItem label="Method:" value={details.payment.method.type} />
        <DetailItem label="Installments:" value={`${details.payment.method.installments}x`} />
        <DetailItem label="Card type:" value={details.payment.method.cardType} />
        <DetailItem label="Card holder:" value={details.payment.method.cardHolder} />
        <DetailItem label="Card Number:" value={`**** **** **** ${details.payment.method.cardNumber}`} />
        <DetailItem label="Expiry Date:" value={
          `${details.payment.method.expirationMonth.toString().padStart(2, '0')}/${details.payment.method.expirationYear}`} />
        <DetailItem label="FX Rate:" value="5,56" />  
        <DetailItem label="GBP Amount:" value={<FormattedNumber minimumFractionDigits={2} maximumFractionDigits={2} value={details.payment.amount.gbp} />} currency={'£'} />
        <DetailItem label="BRL Amount:" value={<FormattedNumber minimumFractionDigits={2} maximumFractionDigits={2} value={details.payment.amount.brl} />} currency={'R$'} />
        </>
      )
    }
    else {
      return (
        <>
        <GhostDetailItem label="Status:" color="green" width={150} height={18} />
        <GhostDetailItem label="Transaction code:" width={150} height={18}/>
        <GhostDetailItem label="Fraud score:" width={150} height={18} />
        <GhostDetailItem label="Method:" width={150} height={18}  />
        <GhostDetailItem label="Installments:" width={150} height={18} />
        <GhostDetailItem label="Card type:" width={150} height={18} />
        <GhostDetailItem label="Card holder:" width={150} height={18} />
        <GhostDetailItem label="Card Number:" width={150} height={18} />
        <GhostDetailItem label="Expiry Date:" width={150} height={18} />
        <GhostDetailItem label="Fx Rate:" width={150} height={18} />
        <GhostDetailItem label="GBP Amount:" width={150} height={18} />
        <GhostDetailItem label="BRL Amount:" width={150} height={18} />
        </>
      )
    }
  }

  const boxTransactionCostDetails = () => {
    if(details)
    {
      return(
        <>
        <DetailItem label="Preme fee:" value="£0.60 (R$2.53)" color="" />
        <DetailItem label="Additional fees:" value="£0.00 (R$0.00)" />
        <DetailItem
          label="Sub total:"
          value={`£${details.payment.amount.gbp + 0.6} (R$ ${details.payment.amount.brl + 2.53})`}
          color=""
        />
        </>
      )
    }
    else {
      return (
        <>
        <GhostDetailItem label="Preme fee:" color="" width={150} height={18} />
        <GhostDetailItem label="Additional fees:" width={150} height={18} />
        <GhostDetailItem
          label="Sub total:"
          color=""
          width={150} height={18}
        />
        </>
      )
    }
  }


  return (
    <>
      <div className="left">
        <div className="box-details">
          <header className="header">
            <FormattedMessage id="transactions.order.details" />
          </header>
          <div className="details-items">
            {boxOrderDetails()}
          </div>
        </div>

        <div className="box-details">
          <header className="header">
            <FormattedMessage id="transactions.customer.details" />
          </header>
          <div className="details-items">
            {boxCustomerDetails()}
          </div>
        </div>
        
        <div className="box-details">
          <header className="header">
            <FormattedMessage id="transactions.order.events" />
          </header>
          <div className="details-items">
            {boxEvents()}
          </div>
        </div>
      </div>

      <div className="right">
        <div className="box-details">
          <header className="header">
            <FormattedMessage id="transactions.payment.details" />
          </header>
          <div className="details-items">
            {boxPaymentDetails()}
          </div>
        </div>

        <div className="box-details">
          <header className="header">
            <FormattedMessage id="transactions.cost" />
          </header>
          <div className="details-items">
            {boxTransactionCostDetails()}
          </div>
        </div>

        <div className="box-details">
          <header className="header">
            <FormattedMessage id="transactions.refund.control" />
          </header>
          <div className="details-items">
            <div className="detail-item">
              <div className="label">
                <FormattedMessage id="request.payment.currency" />
              </div>
              <div className="value">
                <FormattedMessage id="request.payment.currency.value" />
              </div>
            </div>
            <div className="detail-item">
              <div className="label">
                <FormattedMessage id="request.payment.amount" />
              </div>
              <div className="value">
                <CurrencyInput id="refund-amount-input" handler={handleRefundAmountChange} />
              </div>
            </div>
            <div className="detail-item">
              <div className="label">
                <FormattedMessage id="transactions.refund.control.reference" />
              </div>

              <div className="value">
                <Input name="" handler={handleRefundReferenceChange} type="text" />
              </div>
            </div>
            <div className="detail-item">
              <div className="label">
                <FormattedMessage id="transactions.refund.control.percentage" />
              </div>
              <div className="value"> {refund? refund.percentage : 0}% </div>
            </div>

            <div className="details-button">
              <Button
                label="Send refund"
                icon="/images/arrow-down-circle.svg"
                css="arrow-right send-refund-btn"
                handler={handleRefundButtonClick}
              />
            </div>

            <div className="refund-feedback">
              {refundFeedbackMessage()}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BoxDetails;
