import React from 'react';
import Skeleton, {SkeletonTheme} from 'react-loading-skeleton';

type DetailData = {
    label: string;
    value: any;
    color?: string;
    currency?: string
}

type GhostDetailData = {
    label: string;
    color?: string;
    width: number;
    height: number;
}

const DetailItem = ({ label, value, color, currency }: DetailData) => {
    console.log(currency);
    return (
        <div className="detail-item" >
            <div className="label"> {label} </div>
            <div className={`value ${color}`}> {(currency !== undefined) ? `${currency} ` : null}{value} </div>
        </div >
    )
}

export const GhostDetailItem = ({ label, color, width, height }: GhostDetailData) => {
    return (
        <SkeletonTheme color="#202020" highlightColor="#444">
            <div className="detail-item" >
                <div className="label"> {label} </div>
                <div className={`value ${color}`}> <Skeleton width={width} height={height}/> </div>
            </div >
        </SkeletonTheme>
    )
}

export const GhostEventItem = ({width, height}: any) => {
    return (
    <>
    <SkeletonTheme color="#202020" highlightColor="#444">
        <Skeleton width={width} height={height}/>
    </SkeletonTheme>
    </>
    )
}

export default DetailItem
