import React from "react";
import Sidebar from "../components/Sidebar/Index";
import {
  LayoutWrapper,
  ContentBox,
  InfoLabel,
  Button
} from "../components/Layout/Index";
import TopBar from "../components/Layout/TopBar/Index";
import ReportChart from "../components/ReportChart";
import FaqItem from "../components/FAQ";
import { FormattedMessage, FormattedNumber } from "react-intl";
import RecentOrdersList from '../components/RecentOrders';

function Home() {
  return (
    <>
      <Sidebar />
      <LayoutWrapper>
        <TopBar />
        <ContentBox cssClass="report-charts-wrapper">
          <ReportChart />
        </ContentBox>
        <div className="row_lists">
          <ContentBox width="60%" cssClass="m-top-25">
            <div className="table-list">
              <div className="title">
                <FormattedMessage id="home.recent-orders" />
              </div>
              <div className="header">
                <ul>
                  <li>
                    <FormattedMessage id="home.status" />
                  </li>
                  <li>
                    <FormattedMessage id="home.order" />
                  </li>
                  <li>
                    <FormattedMessage id="home.name" />
                  </li>
                  <li>
                    <FormattedMessage id="home.method" />
                  </li>
                  <li>
                    <FormattedMessage id="home.brl.amount" />
                  </li>
                  <li>
                    <FormattedMessage id="home.gbp.amount" />
                  </li>
                  <li>
                    <FormattedMessage id="home.order.type" />
                  </li>
                </ul>
              </div>
              <div className="table-items latest-orders">
                <RecentOrdersList />
{/* 
                <ul>             
                  <li className="green">
                    <FormattedMessage id="payment.confirmed" />
                  </li>
                  <li>#98989898</li>
                  <li>Philip Gutierrez</li>
                  <li>
                    <FormattedMessage id="payment.credit.card" />
                  </li>
                  <li>
                    R$
                    <FormattedNumber value={542.21} />
                  </li>
                  <li>
                    &#8356;
                    <FormattedNumber value={542.21} />
                  </li>
                </ul>

                <ul>
                  <li className="red">
                    <FormattedMessage id="payment.refused" />
                  </li>
                  <li>#98989898</li>
                  <li>Philip Gutierrez</li>
                  <li>
                    <FormattedMessage id="payment.credit.card" />
                  </li>
                  <li>
                    R$
                    <FormattedNumber value={542.21} />
                  </li>
                  <li>
                    &#8356;
                    <FormattedNumber value={542.21} />
                  </li>
                </ul>

                <ul>
                  <li className="green">
                    <FormattedMessage id="payment.confirmed" />
                  </li>
                  <li>#98989898</li>
                  <li>Philip Gutierrez</li>
                  <li>
                    <FormattedMessage id="payment.credit.card" />
                  </li>
                  <li>
                    R$
                    <FormattedNumber value={542.21} />
                  </li>
                  <li>
                    &#8356;
                    <FormattedNumber value={542.21} />
                  </li>
                </ul>

                <ul>
                  <li className="yellow">
                    <FormattedMessage id="payment.pending" />
                  </li>
                  <li>#98989898</li>
                  <li>Philip Gutierrez</li>
                  <li>
                    <FormattedMessage id="payment.credit.card" />
                  </li>
                  <li>
                    R$
                    <FormattedNumber value={542.21} />
                  </li>
                  <li>
                    &#8356;
                    <FormattedNumber value={542.21} />
                  </li>
                </ul>

                <ul>
                  <li className="green">
                    <FormattedMessage id="payment.confirmed" />
                  </li>
                  <li>#98989898</li>
                  <li>Philip Gutierrez</li>
                  <li>
                    <FormattedMessage id="payment.credit.card" />
                  </li>
                  <li>
                    R$
                    <FormattedNumber value={542.21} />
                  </li>
                  <li>
                    &#8356;
                    <FormattedNumber value={542.21} />
                  </li>
                </ul>
*/}
              </div>
            </div>
          </ContentBox>
          <ContentBox width="unset" cssClass="list-box map">
            <div className="title">
              <FormattedMessage id="home.live.map" />
            </div>
            <div className="map">
              <img src="/images/map.svg" alt="map" />
            </div>
          </ContentBox>
        </div>
        <div className="row_lists">
          <ContentBox width="unset" cssClass="m-top-25 finance-bottom flex-1">
            <div className="finance-box">
              <InfoLabel label="Available to Settlement" value="£35,425.42" />
              <InfoLabel label="On hold" value="£35,425.42" />
            </div>
            <div>
              <Button
                label="Withdrawal"
                handler={() => {}}
                icon="/images/arrow-down-circle.svg"
                css="btn-small"
              />
            </div>
            <div className="separator"></div>
            <div className="box-finance-bottom">
              <InfoLabel label="Complains" value="£7,426.23" />
              <div className="content">
                <FormattedMessage id="home.complains.help" />
              </div>
            </div>
          </ContentBox>
          <ContentBox
            width="60%"
            cssClass="m-top-25 m-left-25 padding-vertical-unset"
          >
            <div className="faq">
              <div className="title">
                <FormattedMessage id="home.faq" />
              </div>
              <FaqItem
                question="What is Boleto Bancário?"
                answer="Boleto Bancário, simply referred to as Boleto (English: Ticket) is a payment method in Brazil."
              />
              <FaqItem
                question="How long takes to Boleto Bancário be confirmed?"
                answer="Boleto payments are typically confirmed with a delay of 2-3 business days."
              />
              <FaqItem
                question="Do recurring payments work automatically?"
                answer="Yes! Our technology allows you to set the recurring payments and the customer will be charged automatically."
              />
              <FaqItem
                question="Can I set recurring payment periods as I need?"
                answer="Yes! You can choose to charge in a Weekly/Month/Annually/Daily basis your customers"
              />
              <FaqItem
                question="How to request a payment by url or email?"
                answer="You can use the button Request Payment on the header area of your dashboard"
              />
              <FaqItem
                question="What FX Rate I've paid?"
                answer="The Exchange changes constantly during business hours. To double check the exchange rate used, go to the Order Details page and you will find on the Payment Details section."
              />
            </div>
            <div className="help">
              <div className="title">
                <FormattedMessage id="home.help" />
              </div>
              <div className="list-with-icons">
                <a href="#/" className="item">
                  <img src="/images/chat-icon.svg" alt="chat" />
                  <div className="label">
                    <FormattedMessage id="home.chat.wait.time" />
                  </div>
                </a>
                <a href="#/" className="item">
                  <img src="/images/email-icon.svg" alt="chat" />
                  <div className="label">
                    <FormattedMessage id="home.email.wait.time" />
                  </div>
                </a>
                <a href="#/" className="item">
                  <img src="/images/phone-icon.svg" alt="chat" />
                  <div className="label">
                    <FormattedMessage id="home.phone.wait.time" />
                  </div>
                </a>
              </div>
            </div>
          </ContentBox>
        </div>
      </LayoutWrapper>
    </>
  );
}

export default Home;
