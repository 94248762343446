import React from 'react';
import ReactDOM from 'react-dom';
import AppRoutes from './router/Index';
import * as serviceWorker from './serviceWorker';
import { IntlProvider } from 'react-intl';
import resources from './resources/resources';
import './assets/css/index.scss'
import 'bootstrap/dist/css/bootstrap.min.css';


const messages = resources.getMessages(navigator.language);

ReactDOM.render(<IntlProvider locale={navigator.language} messages={messages}>
    <AppRoutes />
</IntlProvider>, document.getElementById('root'));

serviceWorker.register();