import React from 'react'
import ReactApexChart from "react-apexcharts";


class LineChart extends React.Component {
    state = {
        options: {
            chart: {
                zoom: {
                    enabled: false
                }
            },
            dataLabels: {
                enabled: false
            },
            stroke: {
                curve: 'straight'
            },
            xaxis: {
                categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep'],
            },
            colors: ['#FF7276', '#FFFFFF', '#FFFFFF', '#FFFFFF'],
        },
        series: [{
            name: "Desktops",
            data: [10, 41, 35, 51, 49, 62, 69, 91, 148]
        }],
    }

    render() {

        return (
            <ReactApexChart
                options={this.state.options}
                series={this.state.series}
                type="line"
                width="100%"
                height="300" />
        );
    }
}

export default LineChart
