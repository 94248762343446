import React, { useContext } from 'react'
import { Link } from 'react-router-dom'

import { Context } from '../../store/AppContext'

interface MenuItemData {
    name: string;
    icon: string;
    url: string;
}
const MenuItem = ({ name, icon, url }: MenuItemData) => {
    let store: any = useContext(Context);
    let checkUrl = url === '/' ? '/' : url
    let active = store.data.currentPage === checkUrl ? true : false;
    icon = active ? icon.replace(/.svg/, `_active.svg`) : icon;
    return (
        <Link to={url} onClick={() => store.setData({ currentPage: url })} className={`menu-nav_item ${active ? 'active' : null}`}>
            <img src={icon} alt="" />
            <div className="label">{name}</div>
        </Link>
    )
}

export default MenuItem
