export const FormatTime = (date: Date) => {
    const day = date.getDate().toString().padStart(2, '0');
    const month = date.getMonth().toString().padStart(2, '0');
    const year = date.getFullYear();
    const hour = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    const seconds = date.getSeconds().toString().padStart(2, '0');

    return `${day}/${month}/${year} - ${hour}:${minutes}:${seconds}`
}

export const FormatDate = (date: Date) => {
    const day = date.getDate().toString().padStart(2, '0');
    const month = date.getMonth().toString().padStart(2, '0');
    const year = date.getFullYear();

    return `${day}/${month}/${year}`
}

export const State = (acronym: string) => {
    const states = [
        {acronym: "SC", name: "Santa Catarina"}
    ]

    var state = states.find(x => x.acronym == acronym);
    if(state != null)
        return state.name;
    else
        return acronym;
}

export const Country = (acronym: string) => {
    const states = [
        {acronym: "BR", name: "Brasil"}
    ]

    var country = states.find(x => x.acronym == acronym);
    if(country != null)
        return country.name;
    else
        return acronym;
}