import React from 'react';
import { Link } from "react-router-dom";
import { FormattedMessage, FormattedNumber } from "react-intl";

type customer = {
  name: string
}

type method = {
  installments: number
}

type amount = {
  brl: number,
  gbp: number
}

type item = {
  number: string,
  customer: customer,
  amount: amount,
  method: method,
  status: number,
  type: string
}


const Item = ({ number, customer, amount, method, status, type }: item) => {
    let _status: any = {};
    if(status == 1)
    {
      _status["name"] = "payment.confirmed";
      _status["color"] = "green"
    }
    else {
      _status["name"] = "payment.refused";
      _status["color"] = "red"
    }
    
    return (
      <>
        <Link to={`/details/${number}`}>
          <ul>
            <li className={_status.color}>
              <FormattedMessage id={_status.name} />
            </li>
            <li>#{number.slice(0,7)}</li>
            <li>{customer.name}</li>
            <li><FormattedMessage id="payment.credit.card" /></li>
            <li>
              R$
              <FormattedNumber minimumFractionDigits={2} maximumFractionDigits={2} value={amount.brl} />
            </li>
            <li>
              £
              <FormattedNumber minimumFractionDigits={2} maximumFractionDigits={2} value={amount.gbp} />
            </li>
            <li>{type}</li>
          </ul>
        </Link>
      </>
    )
};

export default Item;