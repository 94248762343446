import React from 'react'
import { LayoutWrapperMobile } from '../components/Layout/Index'
import PlanBox from '../components/Plans/Box'
import Title from '../components/Plans/Title'

function Plans() {
    return (
        <>
            <LayoutWrapperMobile>
                <Title text="Escolha um plano" />
                <PlanBox title="Premium" value="15,50" frequency="por mês" />
                <PlanBox title="Premium Família" value="27,50" frequency="por mês" color="dark" />
            </LayoutWrapperMobile>
        </>
    )
}

export default Plans