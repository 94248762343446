import React from 'react';
import PaymentRequest from './PaymentRequest';
import { Logo } from '../Index'
import './topbar.scss'


export const TopBar = (props: any) => {

    return (<div className="topbar">
        <Logo />
        <div className="left">
            <PaymentRequest />
        </div>
    </div>)
}

export default TopBar;