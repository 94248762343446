import axios from '../helpers/axiosInstancePagZoop'
import environment from '../config/environment'
import CreditCard from '../models/CreditCard';
import Transaction from '../models/Transaction';
import Buyer from '../models/Buyer';

class PagZoopService {
    async getPayments() {
        return await axios.get(`${environment.pagZoopApi.url}payments/get-payments`);
    }
    async getPaymentById(paymentId: string) {
        return await axios.get(`${environment.pagZoopApi.url}payments/get-payments/${paymentId}`);
    }
    async createCreditCardToken(creditCard: CreditCard) {
        return await axios.post(`${environment.pagZoopApi.url}marketplaces/${environment.pagZoopApi.Marketplace_id}/cards/tokens`, creditCard);
    }
    async createTransaction(transaction: Transaction) {
        return await axios.post(`${environment.pagZoopApi.url}marketplaces/${environment.pagZoopApi.Marketplace_id}/transactions`, transaction);
    }
    async createBuyer(buyer: Buyer) {
        return await axios.post(`${environment.pagZoopApi.url}marketplaces/${environment.pagZoopApi.Marketplace_id}/buyers`, buyer);
    }
    async assossiateCreditCardToBuyer(creditCardToken: string, customer_id: string) {
        const data = {
            token: creditCardToken,
            customer: customer_id
        }
        return await axios.post(`${environment.pagZoopApi.url}marketplaces/${environment.pagZoopApi.Marketplace_id}/cards`, data);
    }
}

export default new PagZoopService();