import LangResource from "./LangResource";

const cn = new LangResource();

cn.langKeys = ["cn"];
cn.messages = {
  "home.recent-orders": "Recent orders",
  "home.status": "Status",
  "home.order": "Order",
  "home.name": "Name",
  "home.method": "Method",
  "home.brl.amount": "BRL Amount",
  "home.gbp.amount": "GBP Amount",
  "home.live.map": "Live Map",
  "home.faq": "FAQ",
  "home.help": "Help",
  "home.complains.help":
    "Need help on one of the complains? Speak with one of our Premer Agents by chat.",
  "home.chat.wait.time": "Average wait 1 minute",
  "home.email.wait.time": "Average wait 2 hours",
  "home.phone.wait.time": "Average wait 10 minutes",
  "home.overview": "Overview",
  "home.report": "Report",
  "home.between": "between",
  "home.subtotal": "Subtotal",
  "home.average.order": "Average order",
  "home.orders.quantity": "Orders quantity",
  "home.complains": "Complains",
  "home.performance": "Performance",
  "home.performance.hint":
    "This is how much you have grown if we compare the same period before and after your company joins Preme",
  "payment.confirmed": "Confirmed",
  "payment.pending": "Pending",
  "payment.refused": "Refused",
  "payment.credit.card": "Credit Card",
  "payment.debit.card": "Debit Card",
  "payment.boleto.bancario": "Boleto Bancário",
  "request.payment": "Request Payment",
  "request.payment.customer.name": "Customer name:",
  "request.payment.customer.email": "Customer email:",
  "request.payment.currency": "Currency:",
  "request.payment.currency.value": "BRL (R$):",
  "request.payment.amount": "Amount:",
  "request.payment.order.number": "Order number:",
  "request.payment.due.date": "Due payment date:",
  "transactions.title": "Transactions",
  "transactions.order.details": "Order Details",
  "transactions.customer.details": "Customer Details",
  "transactions.payment.details": "Payment Details",
  "transactions.cost": "Transaction Cost",
  "transactions.refund.control": "Refund Control",
  "transactions.refund.control.reference": "Reference:",
  "transactions.refund.control.percentage": "Percentage:",
  "login.welcome": "Welcome",
  "login.button.login": "Login"
};

export default cn;
