import React from "react";
import "./payment.scss";
import visa from "../../assets/images/visa.png";
import { FormattedMessage } from "react-intl";

function Processing() {
  return (
    <div className="payment-processing-container">
      <div className="visa">
        <img src={visa} />
      </div>
      <span className="processing-payment-text">
        <FormattedMessage id="request.payment.processing" />
      </span>
    </div>
  );
}

export default Processing;
