import React from "react";
import { Select } from "../Layout/Index";
import SingleChart from "./SingleChart";
import { ProgressBar } from "react-bootstrap";
import { FormattedMessage, FormattedDate, FormattedNumber } from "react-intl";
import "./reportChart.scss";

function actionSelect() {
  return false;
}

const ReportChart = (props: any) => {
  return (
    <div className="report-chart">
      <div className="box-chart">
        <div className="report-chart_top">
          <div className="left">
            <div className="title">
              <FormattedMessage id="home.overview" />
            </div>
          </div>
          <div className="right">
            <Select
              name="currency"
              mainLabel="Currency"
              options={[{ value: 1, label: "£ Brititsh Pound" }]}
              action={actionSelect}
            />

            <Select
              name="currency"
              mainLabel="Filter"
              options={[{ value: 1, label: "Last 7 days" }]}
              action={actionSelect}
            />
          </div>
        </div>
        <div className="chart-wrapper">
          <SingleChart />
        </div>
      </div>
      <div className="report-totals">
        <div className="label-top">
          <FormattedMessage id="home.report" />{" "}
          <span>
            <FormattedMessage id="home.between" />{" "}
            <FormattedDate value={new Date()} />
          </span>
        </div>
        <div className="list">
          <div className="list-item">
            <div className="list-item-label">
              <FormattedMessage id="home.subtotal" />
            </div>
            <div className="list-item-value">
            £<FormattedNumber value={628239.12} />
            </div>
          </div>
          <div className="list-item">
            <div className="list-item-label">
              <FormattedMessage id="home.average.order" />
            </div>
            <div className="list-item-value">
            £<FormattedNumber value={628239.12} />
            </div>
          </div>
          <div className="list-item">
            <div className="list-item-label">
              <FormattedMessage id="home.orders.quantity" />
            </div>
            <div className="list-item-value">
            £<FormattedNumber value={628239.12} />
            </div>
          </div>
          <div className="list-item">
            <div className="list-item-label">
              <FormattedMessage id="home.complains" />
            </div>
            <div className="list-item-value">
            £<FormattedNumber value={628239.12} />
            </div>
          </div>
        </div>
        <div className="metrics">
          <div className="perfomance">
            <div className="left">
              <div className="label">
                <FormattedMessage id="home.performance" />
              </div>
              <div className="value">+29.%</div>
              <ProgressBar variant="success" now={29} />
            </div>
            <div className="right">
              <FormattedMessage id="home.performance.hint" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReportChart;
