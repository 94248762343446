import React, { useState } from "react";
import Input, {
  ExpirationDateInput,
  CardNumberInput,
  SecurityCodeInput
} from "../Forms/Input";
import FormButton from "../Forms/Button";
import CreditCard from "../../models/CreditCard";
import PagZoopService from "../../services/PagZoop";
import Transaction from "../../models/Transaction";
import environment from "../../config/environment";

export default class PaymentForm extends React.Component {
  state = {
    card_number: "",
    expiration_month: "",
    expiration_year: "",
    security_code: "",
    holder_name: ""
  };

  handleCreditCardNumber = (event: any) => {
    this.setState({
      card_number: event.target.value
    });
  };

  handleExpirationDateNumber = (event: any) => {
    const expirationDate = event.target.value;
    const [expirationMonth, expirationYear]: any = expirationDate.split("/", 2);
    this.setState({
      expiration_month: expirationMonth,
      expiration_year: expirationYear
    });
  };

  handleSecurityCodeNumber = (event: any) => {
    this.setState({
      security_code: event.target.value
    });
  };

  handleHolderName = (event: any) => {
    this.setState({
      holder_name: event.target.value
    });
  };

  handleSubmitClick = async (event: any) => {
    const creditCard = new CreditCard();
    creditCard.card_number = this.state.card_number;
    creditCard.expiration_month = this.state.expiration_month;
    creditCard.expiration_year = this.state.expiration_year;
    creditCard.security_code = this.state.security_code;
    creditCard.holder_name = this.state.holder_name;

    const creditCardToken = await PagZoopService.createCreditCardToken(
      creditCard
    );

    const transaction = new Transaction();
    transaction.amount = 27;
    transaction.currency = "BLR";
    transaction.description = "Pagamento plano Premium Familia";
    transaction.statement_descriptor = "Preme Pay Music Streaming";
    transaction.on_behalf_of = environment.pagZoopApi.token;
    transaction.token = creditCardToken.data;
    transaction.payment_type = "credit";
    transaction.installment_plan.mode = "interest_free";
    transaction.installment_plan.number_installments = 1;

    await PagZoopService.createTransaction(transaction);
  };

  render() {
    return (
      <div className="payment-form">
        <div className="form-container">
          <CardNumberInput
            label="Número do cartão"
            name="card_number"
            handler={this.handleCreditCardNumber}
          />
          <div className="row">
            <ExpirationDateInput
              label="Data de expiração"
              name="expiration_date"
              className="col"
              handler={this.handleExpirationDateNumber}
            />
            <SecurityCodeInput
              label="Código de segurança"
              name="security_code"
              className="col"
              handler={this.handleSecurityCodeNumber}
            />
          </div>
          <Input
            label="Nome impresso no cartão"
            name="holder_name"
            handler={this.handleHolderName}
          />
          <FormButton text="Pagar agora" handler={this.handleSubmitClick} />
        </div>
      </div>
    );
  }
}
