import React, { useState } from 'react'
import './faq.scss'
type FaqItemData = {
    question: string;
    answer: string;
}
const FaqItem = ({ question, answer }: FaqItemData) => {
    let [isOpen, setIsOpen] = useState(false);
    return (
        <div className="faq-item">
            <div onClick={e => setIsOpen(!isOpen)} className="question">
                <span className={`${isOpen ? 'active' : ''}`}>{question}</span>
            </div>
            {isOpen && <div className="answer"> {answer}</div>}
        </div>
    )
}

export default FaqItem
