const environment = {
    prod: false,
    clearSaleApi: {
        url: 'https://cors-anywhere.herokuapp.com/https://homologacao.clearsale.com.br/api/v1/'
    },
    pagZoopApi: {
        url: 'https://cors-anywhere.herokuapp.com/https://api.sandbox.premepay.com/v1/',
        token: 'a382eeaeb2164486afbc2d784fc07124',
        Marketplace_id: 'a382eeaeb2164486afbc2d784fc07124'
    }
}

export default environment;