import LangResource from "./LangResource";
import en from "./en";
export default class Resource {
  messages: LangResource[] = [];

  getMessages(lang: string) {
    let result = this.messages.find(x => x.langKeys.some(_ => _ === lang));

    if (!!result) return result.messages;

    return en.messages;
  }
}
