import React from 'react'
import UserInfo from './UserInfo'
import MenuItem from './MenuItem'
import './sidebar.scss'
import { Link } from 'react-router-dom'

const handleSignOutClick = (event: any) => {
    event.preventDefault();
    localStorage.removeItem('token');
    localStorage.removeItem('token-expiry-at');
    document.location.href = '/';
}

const Index = () => {
    return (
        <div className="sidebar">
            <UserInfo name="Company" lastName="Name" avatarImage="" />
            <div className="menu_items">
                <MenuItem name="Home" icon="/images/home.svg" url="/" />
                <MenuItem name="Transactions" icon="/images/price.svg" url="/transactions" />
                <MenuItem name="Reports" icon="/images/reports.svg" url="/reports" />
                {/*<MenuItem name="Sign Out" icon="/images/sign-out.svg" url="/login" />*/}
                <a href="" onClick={(e) => handleSignOutClick(e)} className="menu-nav_item">
                    <img src={"/images/sign-out.svg"} alt="" />
                    <div className="label">Sign Out</div>
                </a>
            </div>
        </div>
    )
}


export default Index
