import React from "react";
import {
  ContentBox,
  LayoutWrapper,
} from "../components/Layout/Index";
import TopBar from "../components/Layout/TopBar/Index";
import Sidebar from "../components/Sidebar/Index";
import TransactionsList from '../components/Transactions';

function Transactions() {
  return (
    <>
      <Sidebar />
      <LayoutWrapper>
        <TopBar />
        <div className="row_lists">
          <ContentBox cssClass="m-top-25 list-transactions">
            <TransactionsList />
          </ContentBox>
        </div>
      </LayoutWrapper>
    </>
  );
}

export default Transactions;
