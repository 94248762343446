import React, {useState, useEffect} from 'react';
import { FormattedMessage, FormattedNumber } from "react-intl";
import ApiService from '../../services/ApiService';
import Skeleton, {SkeletonTheme} from 'react-loading-skeleton';
import Item from './item';

const RecentOrdersList = () => {

    const [items, setItems] = useState<any[]>([]);

    useEffect(() => {

        ApiService.getOrders({limit: 5})
        .then(response => response.json())
        .then(data => setItems(data));
    
    }, []);

    const list = (items: any) => {
        let _items: any[] = [];
        if(items.length > 0) {
            items.map((item: any, index: any) => {
                _items.push(<Item number={item.number} customer={item.customer} amount={item.amount} method={item.method} status={item.status}
                    type={(item.subscriptionId != null) ? 'Recurring' : 'Single'} />)
            })  
        }
        else {
        for(let i = 0; i < 5; i++) {
            _items.push(
            <div style={{marginTop: "0.5rem"}}>
                <SkeletonTheme color="#3E3D46" highlightColor="#444">
                    <Skeleton height={45}/>
                </SkeletonTheme>
            </div> 
            )
        }
        }
        return _items;
    }

    return (
        <>
        {list(items).map((item, index) => {
            return item;
        })}
        </>
    );
}

export default RecentOrdersList;