import React from 'react'
import { LayoutWrapperMobile } from '../components/Layout/Index'
import '../components/Payment/payment.scss';
import Confirmed from '../components/Payment/Confirmed';


function ConfirmedPayment() {
    return (
        <>
            <LayoutWrapperMobile>
                <div className="payment-container">
                    <Confirmed />
                </div>
            </LayoutWrapperMobile>
        </>
    )
}

export default ConfirmedPayment