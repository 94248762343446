import axios from 'axios';
import promise from 'promise';
import environment from '../config/environment';

let axiosInstance = axios.create();

axiosInstance.interceptors.request.use(function (config: any) {

    let accessToken = environment.pagZoopApi.token;

    if (accessToken) {
        if (config.method !== 'OPTIONS') {
            config.headers.authorization = `Bearer ${accessToken}`;
            config.headers.accept = 'application/json';
        }
    }
    return config;
}, function (error: any) {
    return promise.reject(error);
});


export default axiosInstance;
