import React from 'react';
import './topbar-mobile.scss'
import music from '../../../assets/images/music.png';

export const TopBarMobile = (props: any) => (
    <div className="topbar-mobile">
        <img src={music} alt="Preme Dashboard" />
        <span className="title">Preme Music Streaming</span>
    </div>
)

export default TopBarMobile;