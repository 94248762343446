import React from 'react'
import avatar from '../../assets/images/avatar.svg'

interface UserInfoData {
    name: string;
    lastName: string;
    avatarImage?: string;
}

const UserInfo = ({ name, lastName, avatarImage = '' }: UserInfoData) => {
    return (
        <div className="user-avatar">
            <a className="avatar-photo" href="#/">
                <img src={avatarImage ? avatarImage : avatar} alt="profile" />
            </a>
            <div className="name"><strong> {name} </strong></div>
            <div className="name"> {lastName} </div>
        </div>
    )
}

export default UserInfo
