import React, { createContext, useState } from 'react'
import initalState from './initalState'
export const Context = createContext({});

export const Provider = (props: any) => {
    let [appData, setData] = useState(initalState)
    function updateData(newData: any) {
        console.log(newData)
        let data = { ...appData, ...newData }
        return setData(data)
    }
    let contextStore = {
        data: appData,
        setData: updateData
    };
    return (
        <Context.Provider value={contextStore}>
            {props.children}
        </Context.Provider>
    )
}


