import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import plusCircle from "../../../assets/images/plus-circle.svg";
import Search from "../Search";
import { Button, Input } from "../Index";

import "./payment-request.scss";
import { FormattedMessage } from "react-intl";

const PaymentRequest = () => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <Button label="Request payment" icon={plusCircle} handler={handleShow} />
      <Search />
      <Modal show={show} onHide={handleClose}>
        <Modal.Header>
          <Modal.Title>
            <FormattedMessage id="request.payment" />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="box-payment-request">
            <div className="payment-request-items">
              <div className="payment-request-item">
                <div className="label">
                  <FormattedMessage id="request.payment.customer.name" />
                </div>
                <div className="value">
                  <Input name="" handler={() => {}} type="text" />
                </div>
              </div>
              <div className="payment-request-item">
                <div className="label">
                  <FormattedMessage id="request.payment.customer.email" />
                </div>
                <div className="value">
                  <Input name="" handler={() => {}} type="text" />
                </div>
              </div>
              <div className="payment-request-item">
                <div className="label">
                  <FormattedMessage id="request.payment.currency" />
                </div>
                <div className="value">
                  {" "}
                  <div className="value-text">
                    <FormattedMessage id="request.payment.currency.value" />
                  </div>
                </div>
              </div>
              <div className="payment-request-item">
                <div className="label">
                  <FormattedMessage id="request.payment.amount" />
                </div>
                <div className="value">
                  <Input name="" handler={() => {}} type="text" />
                </div>
              </div>
              <div className="payment-request-item">
                <div className="label">
                  <FormattedMessage id="request.payment.order.number" />
                </div>
                <div className="value">
                  <Input name="" handler={() => {}} type="text" />
                </div>
              </div>
              <div className="payment-request-item">
                <div className="label">
                  <FormattedMessage id="request.payment.due.date" />
                </div>
                <div className="value">
                  <Input name="" handler={() => {}} type="text" />
                </div>
              </div>
            </div>
            <div className="payment-request-button">
              <Button
                label="Send request"
                icon="/images/arrow-down-circle.svg"
                css="arrow-right"
                handler={handleClose}
              />
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default PaymentRequest;
