import React from 'react';
import './Plans.scss';

type PlanBoxData = {
    title: string;
    value: string;
    frequency: string;
    color?: string;
}

function handleClick() {
}

const PlanBox = ({ title, value, frequency, color }: PlanBoxData) => {
    return (
        <div className={color} onClick={handleClick}>
            <div className="plan-box">
                <div className="title"> {title} </div>
                <div className="value"> R${value} {frequency}</div>
            </div>
        </div>
    )
}

export default PlanBox