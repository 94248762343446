import React from 'react'
import Sidebar from '../components/Sidebar/Index'
import { LayoutWrapper, ContentBox } from '../components/Layout/Index'
import TopBar from '../components/Layout/TopBar/Index'
import OrdersChart from '../components/ReportChart/OrdersChart'

function Home() {
    return (
        <>
            <Sidebar />
            <LayoutWrapper>
                <TopBar />
                <ContentBox cssClass="report-charts-wrapper">
                    <OrdersChart id="sales" title='Sales' data={{}} />
                </ContentBox>

                <ContentBox cssClass=" m-top-25">
                    <OrdersChart id="Orders" title='Orders' data={{}} />
                </ContentBox>
            </LayoutWrapper>

        </>
    )
}

export default Home
