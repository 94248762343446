import React from 'react'
import { LayoutWrapper } from '../components/Layout/Index'
import TopBar from '../components/Layout/TopBar/Index'
import Sidebar from '../components/Sidebar/Index'
import BoxDetails from '../components/Details/BoxDetails'
import { useParams } from "react-router-dom";

function Transactions() {
    let { id } = useParams();
    return (
        <>
            <Sidebar />
            <LayoutWrapper>
                <TopBar />
                <div className="row_lists transactions_details">
                    <BoxDetails number={id} />
                </div>
            </LayoutWrapper>
        </>
    )
}

export default Transactions
