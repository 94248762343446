import React from 'react'
import { LayoutWrapperMobile } from '../components/Layout/Index'
import PlanBox from '../components/Plans/Box'
import '../components/Payment/payment.scss';
import PaymentForm from '../components/Payment/Form';

function Payment() {
    return (
        <>
            <LayoutWrapperMobile>
                <div className="payment-container">
                    <PlanBox title="Premium Família" value="27,50" frequency="por mês" color="dark" />
                    <PaymentForm />
                </div>
            </LayoutWrapperMobile>
        </>
    )
}

export default Payment