export default class ApiService {

    static async authenticate(user: any) {
        return await fetch('http://premepay-api-staging.us-east-2.elasticbeanstalk.com/v1/authentication', {
            headers: {'Content-Type': 'application/json'},
            method: 'post',
            body: JSON.stringify({username: user.username, password: user.password})
        })
    }

    static async getOrders({limit}: any) {
        const params = limit ? `?limit=${limit}` : '';
        return await fetch(`http://premepay-api-staging.us-east-2.elasticbeanstalk.com/v1/stores/1/orders${params}`);
    }
    
    static async getOrder(number:string) {
        return await fetch(`http://premepay-api-staging.us-east-2.elasticbeanstalk.com/v1/stores/1/orders/${number}`);
    }

    static async PostRefund(refund:any) {
        return await fetch(`http://premepay-api-staging.us-east-2.elasticbeanstalk.com/v1/stores/${refund.storeId}/payments/${refund.paymentId}/refunds`, {
            headers: {'Content-Type': 'application/json'},
            method: 'post',
            body: JSON.stringify({amount: refund.amount, reference: refund.reference})
        })
    }
}