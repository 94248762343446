import React from 'react';
import './Plans.scss';

type TitleData = {
    text: string;
}

const Title = ({ text }: TitleData) => {
    return (
        <h1 className="page-title">{text}</h1>
    )
}

export default Title