export default class CreditCard {
    holder_name: string;
    expiration_month: string;
    expiration_year: string;
    card_number: string;
    security_code: string

    constructor() {
        this.holder_name = '';
        this.expiration_month = '';
        this.expiration_year = '';
        this.card_number = '';
        this.security_code = ''
    }
}