import React, {useState, useEffect} from "react";
import { createBrowserHistory } from "history";
import "../assets/css/login/main.css";
import "../assets/css/login/util.css";
import { FormattedMessage } from "react-intl";
import ApiService from '../services/ApiService';
/*
let formData = {
  email: "",
  password: ""
};

function handleEmailChange(event: any) {
  formData.email = event.target.value;
}

function handlePasswordChange(event: any) {
  formData.password = event.target.value;
}

function handleSubmit(event: any) {
  event.preventDefault();
  
  if (
    formData.email != "" &&
    formData.email == "PremePay" &&
    (formData.password != "" && formData.password == "kab4Kmuzly")
  ) {
    window.location.href = "/";
  } else {
    alert("Incorrect Email or Password!");
  }
}
*/
const Login = () => {
  
  const [username, setUsername] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [feedback, setFeedback] = useState<string>('');
  const history = createBrowserHistory();

  useEffect(() => {
    const token = localStorage.getItem('token');
    if(token) {
      const expiryAt = localStorage.getItem('token-expiry-at');
      if(expiryAt && parseInt(expiryAt) > (new Date).getTime()) {
        document.location.href = '/'
      }
    }
  }, []);

  const handleSubmit = (event: any) => {
    event.preventDefault();
    ApiService.authenticate({username: username, password: password})
    .then(response => {
      if(!response.ok) {
        setFeedback('Incorrect credentials, please try again or contact your company IT team');
        throw new Error();
      }

      return response.json();
    })
    .then(data => {
      const now = new Date();
      const expiryAt = new Date(now).setHours(now.getHours() + 1);

      localStorage.setItem('token', data.token);
      localStorage.setItem('token-expiry-at', expiryAt.toString());

      document.location.href = '/'
    })
    .catch(error => {
      setFeedback('Incorrect credentials, please try again or contact your company IT team');
      console.log(error);
    });
  }

  const handlePasswordChange = (event: any) => {
    setPassword(event.target.value);
  }

  const handleEmailChange = (event: any) => {
    setUsername(event.target.value);
  }

  return (
    <div className="login-section">
      <div className="limiter">
        <div className="container-login100">
          <div className="wrap-login100">
            <form
              className="login100-form validate-form"
              onSubmit={(e) => handleSubmit(e)}
            >
              <span className="login100-form-title p-b-26">
                <FormattedMessage id="login.welcome" />
              </span>
              <span className="login100-form-title p-b-48">
                <i className="zmdi zmdi-font"></i>
              </span>
              <div className="wrap-input100 validate-input">
                <input
                  className={`input100 ${(username.length !== 0) ? 'has-val' : null}`}
                  type="text"
                  name="email"
                  onChange={handleEmailChange}
                />
                <span
                  className="focus-input100"
                  data-placeholder="Username"
                ></span>
              </div>
              <div className="wrap-input100 validate-input">
                <span className="btn-show-pass">
                  <i className="zmdi zmdi-eye"></i>
                </span>
                <input
                  className={`input100 ${(password.length !== 0) ? 'has-val' : null}`}
                  type="password"
                  name="pass"
                  onChange={handlePasswordChange}
                />
                <span
                  className="focus-input100"
                  data-placeholder="Password"
                ></span>
              </div>
              <div className="login-feedback">{feedback}</div>
              <div className="container-login100-form-btn"> 
                <div className="wrap-login100-form-btn">
                  <div className="login100-form-bgbtn"></div>
                  <button className="login100-form-btn">
                    <FormattedMessage id="login.button.login" />
                  </button>
                </div>
              </div>
            
            </form>
          </div>
        </div>
      </div>
      <div id="dropDownSelect1"></div>
    </div>
  );
};

export default Login;
