import InstallmentPlan from "./InstallmentPlan";

export default class Transaction {
    amount: number;
    currency: string;
    description: string;
    statement_descriptor: string;
    on_behalf_of: string;
    token: string;
    payment_type: string;
    installment_plan: InstallmentPlan;

    constructor() {
        this.amount = 0;
        this.currency = '';
        this.description = '';
        this.statement_descriptor = '';
        this.on_behalf_of = '';
        this.token = '';
        this.payment_type = '';
        this.installment_plan = new InstallmentPlan();
    }
}