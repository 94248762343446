import React from 'react'
import { Provider } from '../store/AppContext'
import { createBrowserHistory } from "history";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Home from '../screens/Home'
import Transactions from '../screens/Transactions'
import TransactionsDetails from '../screens/TransactionDetails'
import Reports from '../screens/Reports'
import Login from '../screens/Login';
import { ScreenWrapper } from './RouterHelpers'
import '../assets/css/index.scss'
import Plans from '../screens/Plans';
import Payment from '../screens/Payment';
import ProcessingPayment from '../screens/ProcessingPayment';
import ConfirmedPayment from '../screens/ConfirmedPayment';


const AppRoutes = () => {
    const token = localStorage.getItem('token');
    const history = createBrowserHistory();
    if(token === undefined || token === null) {
            history.push('/login');
    }
    else {
        const expiryAt = localStorage.getItem('token-expiry-at');
        if(expiryAt == null || parseInt(expiryAt) < (new Date).getTime())
            history.push('/login');
    }

    return (
        <Provider>
            <Router>
                <Switch>
                    <Route path="/login" component={() => ScreenWrapper(<Login />, `login`)} />
                    <Route path="/" exact={true} component={() => ScreenWrapper(<Home />, 'home')} />
                    <Route path="/transactions" component={() => ScreenWrapper(<Transactions />, `transactions`)} />
                    <Route path="/reports" component={() => ScreenWrapper(<Reports />, `details`)} />
                    <Route path="/details/:id" component={() => ScreenWrapper(<TransactionsDetails />, `details`)} />
                    <Route path="/plans" exact component={() => ScreenWrapper(<Plans />, `plans`)} />
                    <Route path="/payment" exact component={() => ScreenWrapper(<Payment />, `payment`)} />
                    <Route path="/processing-payment" exact component={() => ScreenWrapper(<ProcessingPayment />, `processingPayment`)} />
                    <Route path="/confirmed-payment" exact component={() => ScreenWrapper(<ConfirmedPayment />, `confirmedPayment`)} />
                </Switch>
            </Router>
            <Router>
                <Switch >

                </Switch>
            </Router>
        </Provider>
    )
}

export default AppRoutes
