import React, {useState, useEffect} from 'react';
import {
  Pagination,
  Select
} from "../Layout/Index";
import { FormattedMessage, FormattedNumber } from "react-intl";
import { Link } from "react-router-dom";
import ApiService from '../../services/ApiService';
import Item from './item';
import { utimes } from 'fs';
import Skeleton, {SkeletonTheme} from 'react-loading-skeleton';

const TransactionsList = () => {

  const [items, setItems] = useState<any[]>([]);

  const list = (items: any) => {
    let _items: any[] = [];
    if(items.length > 0) {
      items.map((item: any, index: any) => {
        _items.push(<Item number={item.number} customer={item.customer} amount={item.amount} method={item.method} status={item.status} 
          type={(item.subscriptionId != null) ? 'Recurring' : 'Single'} />)
      })
    }
    else {
      for(let i = 0; i < 10; i++) {
        _items.push(
          <div style={{marginTop: "0.5rem"}}>
            <SkeletonTheme color="#3E3D46" highlightColor="#444">
              <Skeleton height={55}/>
            </SkeletonTheme>
          </div> 
        )
      }
    }
    return _items;
  }

  useEffect(() => {

    ApiService.getOrders({})
    .then(response => response.json())
    .then(data => setItems(data));

  }, []);

  

  return(
    <>
      <div className="table-list">
        <div className="content-box_header">
          <div className="title">
            <FormattedMessage id="transactions.title" />
          </div>
          <div className="selects">
            <Select
              mainLabel="Status:"
              name="status"
              options={[{ value: "all", label: "All" }]}
              action={() => {}}
            />
            <Select
              mainLabel="Filter:"
              name="filter"
              options={[{ value: "all", label: "Last 7 days" }]}
              action={() => {}}
            />
            <Select
              mainLabel="Method:"
              name="status"
              options={[{ value: "all", label: "All" }]}
              action={() => {}}
            />
          </div>
        </div>
        <div className="header">
          <ul>
            <li>
              <FormattedMessage id="home.status" />
            </li>
            <li>
              <FormattedMessage id="home.order" />
            </li>
            <li>
              <FormattedMessage id="home.name" />
            </li>
            <li>
              <FormattedMessage id="home.method" />
            </li>
            <li>
              <FormattedMessage id="home.brl.amount" />
            </li>
            <li>
              <FormattedMessage id="home.gbp.amount" />
            </li>
            <li>
              <FormattedMessage id="home.order.type" />
            </li>
          </ul>
        </div>
        <div className="table-items">
          
          {list(items).map((item, index) => {
            return item;
          })}
{/*
          <Link to="/details">
            <ul>
              <li className="green">
                <FormattedMessage id="payment.confirmed" />
              </li>
              <li>#5D5320822B2AB</li>
              <li>Philip Gutierrez</li>
              <li>
                <FormattedMessage id="payment.credit.card" /> (1x)
              </li>
              <li>
                R$
                <FormattedNumber value={542.21} />
              </li>
              <li>
                &#8356;
                <FormattedNumber value={542.21} />
              </li>
            </ul>
          </Link>

          <Link to="/details">
            <ul>
              <li className="yellow">
                <FormattedMessage id="payment.pending" />
              </li>
              <li>#5D531715CEE36</li>
              <li>Philip Gutierrez</li>
              <li>
                <FormattedMessage id="payment.debit.card" />
              </li>
              <li>
                R$
                <FormattedNumber value={542.21} />
              </li>
              <li>
                &#8356;
                <FormattedNumber value={542.21} />
              </li>
            </ul>
          </Link>
          <Link to="/details">
            <ul>
              <li className="red">
                <FormattedMessage id="payment.refused" />
              </li>
              <li>#5D53170FE9F5F</li>
              <li>Philip Gutierrez</li>
              <li>
                <FormattedMessage id="payment.boleto.bancario" />
              </li>
              <li>
                R$
                <FormattedNumber value={542.21} />
              </li>
              <li>
                &#8356;
                <FormattedNumber value={542.21} />
              </li>
            </ul>
          </Link>
          <Link to="/details">
            <ul>
              <li className="green">
                <FormattedMessage id="payment.confirmed" />
              </li>
              <li>#5D5316FEB5F88</li>
              <li>Philip Gutierrez</li>
              <li>
                <FormattedMessage id="payment.credit.card" /> (12x)
              </li>
              <li>
                R$
                <FormattedNumber value={542.21} />
              </li>
              <li>
                &#8356;
                <FormattedNumber value={542.21} />
              </li>
            </ul>
          </Link>
          <Link to="/details">
            <ul>
              <li className="green">
                <FormattedMessage id="payment.confirmed" />
              </li>
              <li>#5D53127B4009D</li>
              <li>Philip Gutierrez</li>
              <li>
                <FormattedMessage id="payment.credit.card" /> (1x)
              </li>
              <li>
                R$
                <FormattedNumber value={542.21} />
              </li>
              <li>
                &#8356;
                <FormattedNumber value={542.21} />
              </li>
            </ul>
          </Link>
          <Link to="/details">
            <ul>
              <li className="green">
                <FormattedMessage id="payment.confirmed" />
              </li>
              <li>#5D530FD8C8521</li>
              <li>Philip Gutierrez</li>
              <li>
                <FormattedMessage id="payment.credit.card" /> (1x)
              </li>
              <li>
                R$
                <FormattedNumber value={542.21} />
              </li>
              <li>
                &#8356;
                <FormattedNumber value={542.21} />
              </li>
            </ul>
          </Link>
          <Link to="/details">
            <ul>
              <li className="green">
                <FormattedMessage id="payment.confirmed" />
              </li>
              <li>#5D530FBAACCD1</li>
              <li>Philip Gutierrez</li>
              <li>
                <FormattedMessage id="payment.credit.card" /> (1x)
              </li>
              <li>
                R$
                <FormattedNumber value={542.21} />
              </li>
              <li>
                &#8356;
                <FormattedNumber value={542.21} />
              </li>
            </ul>
          </Link>
          <Link to="/details">
            <ul>
              <li className="green">
                <FormattedMessage id="payment.confirmed" />
              </li>
              <li>#5D530FB10FEF9</li>
              <li>Philip Gutierrez</li>
              <li>
                <FormattedMessage id="payment.credit.card" /> (1x)
              </li>
              <li>
                R$
                <FormattedNumber value={542.21} />
              </li>
              <li>
                &#8356;
                <FormattedNumber value={542.21} />
              </li>
            </ul>
          </Link>
*/}
        </div>
      </div>
      <div className="pagination-wrapper">
        <Pagination />
      </div>
    </>
  );
};

export default TransactionsList;