import React from "react";
import { Select } from "../Layout/Index";
import "./reportChart.scss";
import SingleChart from "./SingleChart";

function actionSelect() {
  return false;
}
type ReportChartData = {
  title: string;
  data: any;
  id: string;
};
const ReportChart = ({ title, data, id }: ReportChartData) => {
  return (
    <div className="report-chart full">
      <div className="box-chart">
        <div className="report-chart_top">
          <div className="left">
            <div className="title">{title}</div>
          </div>
          <div className="right">
            <Select
              name="currency"
              mainLabel="Currency"
              options={[{ value: 1, label: "£ Brititsh Pound" }]}
              action={actionSelect}
            />

            <Select
              name="currency"
              mainLabel="Filter"
              options={[{ value: 1, label: "Last 7 days" }]}
              action={actionSelect}
            />
          </div>
        </div>
        <div className="chart-wrapper">
          <SingleChart />
        </div>
      </div>
    </div>
  );
};

export default ReportChart;
