import React, { Component } from 'react'
import { MetaTags } from 'react-meta-tags';
import './layout.scss'
import logo from '../../assets/images/logo-premedashboard.svg'
import { TopBarMobile } from './TopBarMobile/Index';
import CurrencyInputField from 'react-currency-input-field'


interface Button {
    label: string;
    icon: string;
    handler: any;
    css?: string;
}

export const Button = ({ label, icon, handler, css = '' }: Button) => {
    return (
        <button className={`btn ${css}`} onClick={(e) => handler(e)}>
            {label}
            <span>
                <img src={icon} alt={`${label.toLocaleLowerCase()}_icon`} />
            </span>
        </button>
    )
}

export const LayoutWrapper = (props: any) => <div className="content-container"> {props.children} </div>
export const LayoutWrapperMobile = (props: any) => {
    return (
        <div className="content-container-mobile">
            <MetaTags>
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            </MetaTags>
            <TopBarMobile />
            {props.children} </div>
    )
}
export const Logo = () => <a href="/"> <img src={logo} alt="Preme Dashboard" /> </a>

export const ContentBox = (props: any) => {
    let { cssClass, color, width } = props;

    let styles = {
        color: color ? color : '#ffffff',
        width: width ? width : '100%'
    }
    return (
        <div className={`content-box ${cssClass ? cssClass : ''}`} style={styles}>
            {props.children}
        </div>
    )
}

type SelectItem = {
    value: any;
    label: string;
}
type SelectData = {
    mainLabel: string;
    name: string;
    options: Array<SelectItem>;
    action: any;
}
export const Select = ({ mainLabel, name, options, action }: SelectData) => (
    <div className="select-default">
        <div className="select-label"> {mainLabel} </div>
        <select name={name} onChange={action}>
            {options.map((item, key) => <option key={key} value={item.value}> {item.label} </option>)}
        </select>
    </div>
)
type InfoLabelData = {
    label: string;
    value: string;
}
export let InfoLabel = ({ label, value }: InfoLabelData) => (
    <div className="info-label">
        <div className="label">
            {label}
        </div>
        <div className="value">
            {value}
        </div>
    </div>
)

type DataInput = {
    label?: string;
    name: string;
    placeholder?: string;
    handler: any;
    css?: string;
    type?: string;
    id?: string;
    mask?: string;
}

type DataCurrencyInput = {
    id: string,
    name?: string,
    handler: any
}

export const Input = ({ id, label, placeholder, handler, name, mask, css = '', type = 'text' }: DataInput) => {
    return (
        <div className={`input-wrapper ${css}`}>
            {label !== '' && <label htmlFor={name}> {label} </label>}
            <input placeholder={placeholder} type={type} name={name} onChange={(e:any) => handler(e)} id={id} />
        </div>
    )
}

export const CurrencyInput = ({id, name, handler}: DataCurrencyInput) => {
    return (
        <div className='input-wrapper'>
            <CurrencyInputField id={id} name={name} onChange={(value, name) => handler(value)} allowDecimals={true} decimalsLimit={2} />
        </div>
    )
}



export class Pagination extends Component {
    state = {
        current: 1
    }
    next = (e: any) => {
        e.preventDefault()
        this.setState({ current: this.state.current + 1 })
    }
    prev = (e: any) => {
        e.preventDefault()
        if (this.state.current > 1) {
            this.setState({ current: this.state.current - 1 })
        }
    }
    render() {
        return (
            <div className="pagination">
                <div className="arrow left" onClick={this.prev}>
                    <img src="/images/arrow-down-circle.svg" alt="" />
                </div>
                <div className="current-item"> {this.state.current} </div>
                <div className="arrow right" onClick={this.next}>
                    <img src="/images/arrow-down-circle.svg" alt="" />
                </div>
            </div>
        )
    }
}
